import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import postAxios from "services/axios-post";
import putAxios from "services/axios-put";
import getAxios from "services/axios-get";
import deleteAxios from "services/axios-delete";

import DatetimePicker from "vuetify-datetime-picker";
import AddUsedCarDialog from "@/components/AddUsedCarDialog";
import EditUsedCarDialog from "@/components/EditUsedCarDialog";
import moment from "moment";

Vue.use(VueAxios, axios, moment);

Vue.use(DatetimePicker);

export default {
  components: {
    AddUsedCarDialog,
    EditUsedCarDialog,
  },
  data() {
    return {
      bumperCarInfo: {},
      bumperDialog: false,
      componentKey: 0,
      snackMessage: "",
      snackbar: false,
      color: "primary",
      bidStartDateModal: false,
      bidEndDateModal: false,
      pageCount: 0,
      imgFile: null,
      editImgFile: null,
      defaultImgPath: null,
      defaultImgPathEdit: null,
      imgUrl: null,
      pageSizeList: [10, 20, 50, 100],
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      bidTypeSelection: [
        { value: "AUTOBID", name: "AUTOBID" },
        { value: "CLOSEBID", name: "CLOSEBID" },
      ],
      editDealerJSON: null,
      editCarModelJSON: null,
      statusRejectedReason: [],
      editUsedCarStatus: {
        carId: "",
        status: "",
        sgcm: "",
        rejectedReason: "",
      },
      delUsedCar: {
        id: "",
        name: "",
      },
      nameSearch: "",
      statusFilter: "",
      total: 0,
      editStatusDialog: false,
      deleteDialog: false,
      token: "",
      headers: [
        { text: "Name", value: "productTitle" },
        { text: "Dealer", value: "dealerName" },
        { text: "Car Plate", value: "carPlateNumber" },
        { text: "Car Brand | Model | Submodel", value: "carModel" },
        { text: "Reg. Date", value: "regDate" },
        { text: "Price", value: "price" },
        { text: "Depre", value: "depre" },
        { text: "Mileage", value: "mileage" },
        { text: "Images", value: "displayImgs" },
        { text: "Status", value: "status" },
        { text: "SaleType", value: "saleType" },
        { text: "Create Time", value: "createTime" },
        { text: "Time Approved" },
        { text: "Time Sold" },
        { text: "Edit" },
        { text: "Bid" },
        { text: "Delete" },
        { text: "Id", value: "id" },
        { text: "Bumper" },

        // { text: "Remarks" },
      ],
      biddingEdit: false,
      biddingCar: {
        bidStartPrice: 0,
        biddingScheduleId: "",
        carId: "",
        contactNum: "",
        editorNotes: "",
        expectPrice: 0,
        handoverDate: "",
        inspected: "",
        goldPrice: 0,
        ownerName: "",
        paperValue: 0,
        bidType: "AUTOBID",
      },
      scheduleBidding: {
        bidStartTime: "",
        bidEndTime: "",
      },
      usedCar: [],
      descriptionLimit: 60,
      coeTypeList: [],
      idTypeList: [],
      vesCategoryList: [],
      coeCatList: [],
      displayImgsFiles: [],
      displayVideoFiles: [],
      displayImgsFilesEdit: [],
      carStatusList: [
        { text: "ACTIVE", value: "ACTIVE" },
        { text: "INACTIVE", value: "INACTIVE" },
        { text: "VALUATION CAR", value: "BIDDING_CAR_VALUATION" },
        { text: "AUTO ADDED", value: "OUTSOURCED_ADD" },
        { text: "AUTO UPDATED", value: "OUTSOURCED_UPD" },
        { text: "BIDDING", value: "BIDDING" },
        { text: "ARCHIVE", value: "ARCHIVE" },
        { text: "PENDING BIDDING", value: "PENDING_BIDDING" },
        { text: "SOLD", value: "SOLD" },
        { text: "PENDING APPROVAL", value: "PENDING_APPROVAL" },
        { text: "REJECTED", value: "REJECTED" },
        { text: "DRAFT", value: "DRAFT" },
      ],
      rejectedReasons: [
        { value: "Inappropriate Watermark" },
        { value: "Invalid information (Car plate, ID type and ID)" },
        { value: "Broken image" },
      ],
      editUsedCarImgs: [],
      dealerNameSearch: "",
      carPlateSearch: "",
    };
  },
  mounted() {
    this.fetchUsedCar();
    this.fetchVesCat();
    this.fetchCOECat();
    this.fetchCOEType();
    this.fetchIDType();
  },
  methods: {
    convertDate(date) {
      var parsedDate = moment
        .utc(date)
        .local()
        .format("YYYY-MM-DD");

      return parsedDate;
    },
    convertDateTime(date) {
      var parsedDate = moment
        .utc(date)
        .local()
        .format("YYYY-MM-DD HH:mm");

      return parsedDate;
    },
    paginationChangeHandler() {
      this.fetchUsedCar();
    },
    delButtonClick(item) {
      this.delUsedCar.id = item.id;
      this.delUsedCar.name = item.productTitle;
      this.deleteDialog = true;
    },
    editStatusButtonClick(item) {
      this.statusRejectedReason = [];
      this.resetEditUsedCarStatus();
      this.editUsedCarStatus.carId = item.id;
      this.editUsedCarStatus.status = item.status;
      this.editUsedCarStatus.sgcm = item.sysRemarks;
      this.editUsedCarStatus.rejectedReason = "";
      const selectedReasons = item.rejectedReason
        ? item.rejectedReason.split(" ; ")
        : [];
      for (let i = 0; i < selectedReasons.length; i++) {
        const element = selectedReasons[i];
        this.rejectedReasons.find(reason => {
          if (element === reason.value) {
            this.statusRejectedReason.push(element);
          }
        });
      }
      this.editStatusDialog = true;
    },
    resetEditUsedCarStatus() {
      this.editUsedCarStatus.carId = "";
      this.editUsedCarStatus.status = "";
      this.editUsedCarStatus.sgcm = "";
      this.editUsedCarStatus.rejectedReason = "";
      this.statusRejectedReason = [];
    },
    bidButtonClick(item) {
      this.biddingCar.carId = item.id;
      this.biddingCar.editorNotes = item.notes;
      this.biddingCar.paperValue = item.scrapValue;
      this.biddingEdit = true;
    },
    doDelUsedCar() {
      const self = this;
      const param = {
        carId: self.delUsedCar.id,
      };
      deleteAxios(
        `${process.env.VUE_APP_SERVER_URL}/carSystem/delCarUsed`,
        param,
        self.token
      ).then(res => {
        if (res.data) {
          self.deleteDialog = false;
          self.fetchUsedCar();
          self.showSnack("Delete Car Successfully", "success");
        }
      });
    },
    async doBidUsedCar() {
      const self = this;

      try {
        postAxios(
          `${process.env.VUE_APP_SERVER_URL}/biddingSystem/addBiddingSchedule`,
          self.scheduleBidding,
          self.token
        ).then(res => {
          if (res.data.httpStatus === "OK") {
            self.biddingCar.biddingScheduleId = res.data.data;
            postAxios(
              `${process.env.VUE_APP_SERVER_URL}/biddingSystem/addBidding`,
              self.biddingCar,
              self.token
            ).then(res => {
              if (res.data.httpStatus === "OK") {
                self.biddingEdit = false;
                self.fetchUsedCar();
                self.showSnack("Car Successfully Send For Bidding", "success");
              }
            });
          }
        });
      } catch (e) {
        console.error(e);
        self.showSnack("Add Bidding Failed" + e, "error");
      }
    },
    async doEditUsedCarStatus() {
      const self = this;
      try {
        self.editUsedCarStatus.rejectedReason = self.statusRejectedReason
          ? self.statusRejectedReason.join(" ; ")
          : null;
        if (
          self.editUsedCarStatus.status === "REJECTED" &&
          !self.editUsedCarStatus.rejectedReason
        ) {
          this.$refs.form.validate();
          return;
        }
        if (
          self.editUsedCarStatus.status === "ACTIVE" &&
          !self.editUsedCarStatus.sgcm
        ) {
          this.showSnack("SGCM url is not filled!", "error");
          return;
        } else {
          putAxios(
            `${process.env.VUE_APP_SERVER_URL}/carSystem/updCarStatus?carId=` +
              self.editUsedCarStatus.carId +
              "&status=" +
              self.editUsedCarStatus.status +
              "&rejectedReason=" +
              self.editUsedCarStatus.rejectedReason,
            self.editUsedCarStatus,
            self.token
          )
            .then(res => {
              if (res.data.httpStatus === "OK") {
                self.editStatusDialog = false;
                self.fetchUsedCar();
                self.showSnack("Edit Car Status Successfully", "success");
                self.resetEditUsedCarStatus();
              }
            })
            .catch(e => {
              const errMsg = e.response.data.message;
              self.showSnack("Edit Car Failed " + errMsg, "error");
            });
        }
      } catch (e) {
        self.showSnack("Edit Car Failed" + e, "error");
      }
    },
    searchType() {
      this.fetchUsedCar();
    },
    handlePageChange(newPage) {
      this.pagination.page = newPage;
      this.fetchUsedCar();
    },
    handlePageSizeChange(newPageSize) {
      this.pagination.limit = newPageSize;
      const newPage = this.total / newPageSize;
      if (this.pagination.page > newPage) {
        this.pagination.page = Math.ceil(newPage);
      }
      this.fetchUsedCar();
    },
    fetchVesCat() {
      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/generic/vesCategory`,
        {},
        ""
      ).then(res => {
        if (res.data) {
          self.vesCategoryList = res.data.data;
        }
      });
    },
    fetchCOECat() {
      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedContent/coeCategoryPair`,
        {},
        ""
      ).then(res => {
        if (res.data) {
          self.coeCatList = res.data.data.content;
        }
      });
    },
    fetchCOEType() {
      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/generic/categoryType`,
        {},
        ""
      ).then(res => {
        if (res.data) {
          self.coeTypeList = res.data.data;
        }
      });
    },
    fetchIDType() {
      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedContent/idTypePair`,
        {},
        ""
      ).then(res => {
        if (res.data) {
          self.idTypeList = res.data.data.content;
        }
      });
    },
    fetchUsedCar() {
      const self = this;
      const params = {
        page: self.pagination.page - 1,
        size: self.pagination.limit,
        productTitle: self.nameSearch,
        carStatus: self.statusFilter,
        dealerName: self.dealerNameSearch,
        carPlate: self.carPlateSearch,
        sort: "car.createTime,desc",
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/carSystem/carUsed`,
        params,
        self.token
      ).then(res => {
        if (res.data) {
          self.usedCar = res.data.data.content;
          self.pagination.noOfPage = res.data.data.totalPages;
        }
      });
    },
    showSnack(msg, color) {
      this.snackMessage = msg;
      this.color = color;
      this.snackbar = true;
    },
    add() {
      this.fetchUsedCar();
      this.showSnack("Add Car Successfully", "success");
    },
    edit() {
      this.fetchUsedCar();
      this.showSnack("Edit Car Successfully", "success");
    },
    clickAuction(auctionFilePath) {
      window.open(auctionFilePath);
    },
    openBumperDialogButtonClick(item) {
      this.bumperCarInfo = item;
      this.bumperDialog = true;
    },
  },
};
