import getAxios from "services/axios-get";
import postAxios from "services/axios-post";
import uploadFile from "services/file-uploader";
import createWatermark from "../../../services/create-watermark";
import uploadSignedImage from "../../../services/aws-upload-image";
import moment from "moment";
import ImgGroupInput from "@/components/ImgGroupInput";
import SalespersonGroupInput from "@/components/SalespersonGroupInput";

export default {
  components: {
    ImgGroupInput,
    SalespersonGroupInput,
  },
  props: {
    vesCategoryList: Array,
    idTypeList: Array,
  },
  data() {
    return {
      auctionFile: null,
      saleTypes: ["REGULAR", "CONSIGNMENT", "AUCTION"],
      addUsedCar: {
        carPlateNumber: "",
        defaultImgPath: "",
        displayImgs: [],
        idNum: "",
        idTypeId: "",
        mileage: 0,
        notes: "",
        seoMetaTitle: "",
        seoMetaDescription: "",
        saleType: "REGULAR",
        salePrice: 0,
        salepersonIds: [],
        videoPaths: [],
        auctionFilePath: "",
        intendedDeRegDate: new Date().toISOString().substr(0, 10),
      },
      editRegDateModal: false,
      radioWatermark: "watermark",
      dealerJSON: null,
      show: false,
      renewDateModal: false,
      dealerEntries: [],
      searchDealer: null,
      isLoading: false,
      assignedSalespersonList: [],
      today: new Date().toISOString().substr(0, 10),
      loadingUpd: false,
    };
  },
  watch: {
    searchDealer(val) {
      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      // Lazily load input items

      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/userSystem/dealer`,
        {
          searchContent: val,
          status: "APPROVED",
        },
        self.token
      )
        .then(res => {
          if (res.data) {
            self.dealerEntries = res.data.data.content;
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  methods: {
    async convertDateFormat(date) {
      const dayDate = date.split("+")[0];
      var parsedDate = moment
        .utc(dayDate)
        .local()
        .format("DDMMYYYY");
      return parsedDate;
    },
    async doAddUsedCar() {
      const self = this;
      self.loadingUpd = true;
      try {
        if (self.auctionFile) {
          await uploadFile(self.auctionFile).then(res => {
            self.addUsedCar.auctionFilePath = res.data.data;
          });
        }

        const isObscure = this.radioWatermark == "watermark" ? true : false;
        const imgResLists = self.$refs.imgs.imgs;
        // const imgResList = [...(await self.$refs.imgs[0].upload())];
        let img;
        for (let i = 0; i < imgResLists.length; i++) {
          img = imgResLists[i];
          if (img) {
            const uploadUrl = await getAxios(
              `${process.env.VUE_APP_SERVER_URL}/api/v2/file-storage/pre-signed-urls`,
              {
                format: "png",
                waterMark: isObscure,
              },
              this.token
            );
            if (isObscure) {
              const watermarkedImage = await createWatermark(img.file);
              if (watermarkedImage) {
                await uploadSignedImage(
                  watermarkedImage,
                  uploadUrl.data.waterMarkedUrl
                );
                self.addUsedCar.displayImgs.push(
                  uploadUrl.data.waterMarkedName
                );
              }
            } else {
              await uploadSignedImage(img.file, uploadUrl.data.originalUrl);
              self.addUsedCar.displayImgs.push(uploadUrl.data.originalName);
            }
          }
        }
        // self.addUsedCar.displayImgs = imageUrls;
        self.addUsedCar.defaultImgPath = self.addUsedCar.displayImgs.shift();
        self.addUsedCar.salepersonIds = self.assignedSalespersonList.map(
          sp => sp.id
        );
        self.addUsedCar.intendedDeRegDate = await this.convertDateFormat(
          self.addUsedCar.intendedDeRegDate
        );
        // console.log("used car: " + JSON.stringify(self.addUsedCar));
        await postAxios(
          `${process.env.VUE_APP_SERVER_URL}/carSystem/addCarUsedForDealerAutoPopulate?dealerId=${self.dealerJSON.id}`,
          self.addUsedCar,
          self.token
        )
          .then(res => {
            if (res.data) {
              self.show = false;
              self.$emit("add");
              this.resetAddUsedCar();
              // screenLeft.resetAddUsedCar();
              self.loadingUpd = false;
            }
          })
          .finally(() => (this.loadingUpd = false));
      } catch (e) {
        self.$emit("error", e);
        self.loadingUpd = false;
        console.error(e);
      }
    },
    async resetAddUsedCar() {
      this.addUsedCar = {
        auctionFilePath: "",
        carPlateNumber: "",
        defaultImgPath: "",
        displayImgs: [],
        idNum: "",
        idTypeId: "",
        mileage: 0,
        notes: "",
        seoMetaTitle: "",
        seoMetaDescription: "",
        saleType: "REGULAR",
        salePrice: 0,
        salepersonIds: [],
        videoPaths: [],
        intendedDeRegDate: new Date().toISOString().substr(0, 10),
      };
      this.dealerJSON = null;
    },
  },
};
